import { string } from "prop-types"
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { toast } from "react-toastify"
import { useLocalCart } from "../hooks/useLocalCart"

export const Store = React.createContext({})

const StoreProvider = ({ children }) => {
  const [storedValue, setValue, emptyStorage] = useLocalCart()
  const [carrito, setCarrito] = useState(storedValue)

  useEffect(() => {
    setValue(carrito)
  }, [carrito]) //eslint-disable-line

  const chequearCarrito = variantId => {
    return carrito.find(cartItem => cartItem.variantId === variantId)
  }

  const agregarAlCarrito = (cartItem,variant,variantId, cantidad) => {
    // console.log({cartItem})
    let existe = chequearCarrito(variantId)
    setCarrito(() => {
      if (existe) {
        // console.log("Existe")
        return carrito.map(item => {
          // console.log("StoreProvider",item)
          if (item.variantId === variantId) {
            return {
              ...item,
              cantidad: existe.cantidad + cantidad,
            }
          }
          return item
        })
      } else setCarrito([...carrito, { ...cartItem,variant,variantId, cantidad }])
    })
    toast.success("Producto agregado al carrito")
  }

  function restarAlCarrito(variantId) {
    const cartItem = carrito.find(
      item => item.variantId === variantId
    )

    if (cartItem.cantidad === 1) {
      setCarrito(() =>
        carrito.filter(item => item.variantId !== cartItem.variantId)
      )
    } else {
      setCarrito(() =>
        carrito.map(item => {
          return item.variantId === cartItem.variantId
            ? {
                ...item,
                cantidad: item.cantidad - 1,
              }
            : item
        })
      )
    }
    toast.warn("Producto removido")
  }

  function totalGasto() {
    let count = 0
    if (Array.isArray(carrito)) {
      carrito.forEach(item => {
        // console.log({item})
        count = count + item.cantidad * item.variants[item.variant].price
        return count
      })
    }
    return count
  }

  function removerAlCarrito(variantId) {
    const nuevoCarrito = carrito.filter(item => item.variantId !== variantId)
    setCarrito(nuevoCarrito)
    toast.warn("Producto removido")
  }

  function totalProductos() {
    let count = 0
    if (carrito) carrito.forEach(item => {
      count = item.cantidad + count
      return count
    })
    return Number(count)
  }

  function checkoutProductos() {
    let url = "https://www.craftsociety.com.ar/cart/add?"

    if (carrito.length > 0) {
      carrito.forEach((item, i) => {
        let param = string
        if (i === 0) {
          param = `id[]=${item.variantId}&quantity=${item.cantidad}`
        } else {
          param = `&id[]=${item.variantId}&quantity=${item.cantidad}`
        }
        url = url.concat("", param)
      })
    }

    emptyStorage()
    navigate(url)
  }

  const [producto, setProducto] = useState(null)

  function setDetalleProducto(producto,size) {
    // console.log({...producto,size:size})
    setProducto({...producto,size:size})

    navigate(`/corona-store/descripcion-producto`)
  }

  function cantidadProducto(producto) {
    const item = chequearCarrito(producto)
    return item.cantidad
  }

  return (
    <Store.Provider
      value={{
        carrito,
        setCarrito,
        chequearCarrito,
        cantidadProducto,
        agregarAlCarrito,
        restarAlCarrito,
        totalGasto,
        removerAlCarrito,
        totalProductos,
        checkoutProductos,
        producto,
        setDetalleProducto,
      }}
    >
      {children}
    </Store.Provider>
  )
}

export default ({ element }) => <StoreProvider>{element}</StoreProvider>
