import React from 'react';
import styles from "./popup.module.scss"

const Popup = ({setShowPopUp, popupData}) => {
    // console.log("popup data: " + popupData);
    const handleClick = () => {
        window.dataLayer.push({
                    'event' : 'GAEvent',
                    'event_category': 'Content',
                    'event_action': `Pop Up`,
                    'event_label': `Conseguilo Acá`,
                    'interaction': 'True',
                    'component_name': 'boton_conseguilo_aca',
                    'element_text': `Conseguilo Acá`
        });
    }

  return (
    <div className={styles.popup_container}>
        <div className={styles.popup} style={{ backgroundImage: `url(${popupData.img})` }}>
        <div onClick={()=>setShowPopUp(false)} className={styles.close_popup}></div>
            <div className={styles.popup_content}>
                <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                    <p>{popupData.text_sup} <hr/> {popupData.text_inf}</p>
                    <a href={popupData.link} target='blank'>
                        <button className={styles.button_popup} onClick={handleClick}>
                            <span className={styles.button_popup_text}>{popupData.cta}</span>
                            {/* {popupData.cta} */}
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Popup;
