import React, { useEffect, useRef, useState } from "react"
import "../../styles/welcome.css"
import logo from "../../../assets/images/logos/logo_color.png"
import Div100vh from "react-div-100vh"
import SEO from "../seo"
import Cookies from "js-cookie"

const AgeGateBoolean = ({ location, setAge }) => {
  const [remember, setRemember] = useState(false)
  console.log("location: ", location)

  let newPath = (location.state?.newPath ?? "/") + location.search
  console.log("newPath:", newPath)

  useEffect(() => {
    if (window) {
      window.dataLayer.push({
        brand: "Corona",
        Zone: "SAZ",
        country: "ARG",
        city: "CABA",
        campaign: `${
          window.location.search.includes("?cmp=")
            ? window.location.search.split("?cmp=")[1].split("&")[0]
            : ""
        }`,
        cms: "0",
        event: "pageView",
        language: "es",
        login: false,
        pageName: "Age Gate",
        pageType: "Basic page",
        SiteType: "Brand",
        product: "NA",
        sku: "NA",
        userUid: "",
        url: "https://www.cervezacorona.com.ar",
        url_campaign: `${
          window.location.search.includes("utm_campaign=")
            ? window.location.search.split("utm_campaign=")[1]
            : ""
        }`,
        Step_number: "",
      })
    }
  }, [])

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        onSubmit()
        event.preventDefault()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
  })

  const onSubmit = () => {
    window.dataLayer.push({
      event: "GAEvent",
      event_category: "Age Gate",
      event_action: "Interaction",
      event_label: "Yes",
      interaction: false,
      component_name: "",
      element_text: "",
    })
    remember
      ? localStorage.setItem("age", "true")
      : sessionStorage.setItem("age", "true")
    setAge(false)
  }

  const onCancel = () => {
    window.dataLayer.push({
      event: "GAEvent",
      event_category: "Age Gate",
      event_action: "Intercation",
      event_label: "No",
      interaction: false,
      component_name: "full_age_gate",
      element_text: "Full Age gate",
    })
    window.location.href = "http://www.vivamosresponsablemente.com"
  }

  // const handleCookie = () => {
  //   if (remember) {
  //     Cookies.set("age", true, { expires: 7 })
  //   } else {
  //     sessionStorage.setItem("age", true)
  //   }
  // }

  return (
    // <LayoutConfirmation>
    //   <SEO title="index" />
    //   {/* <Confirmation/> */}

    // </LayoutConfirmation>
    <Div100vh>
      <SEO title="Age Gate" />
      {/* logo */}
      <div className="row">
        <div className="col welcomeContentLogo">
          <img src={logo} alt="logo" className="welcomeLogo" />
        </div>
      </div>

      {/* info */}
      <div className="row welcomeContentInfo">
        <div className="col">
          {/* title */}
          <div className="row">
            <div className="col">
              <h1 className="welcomeTitle">Bienvenido</h1>
              <h3 className="welcomeSubtitle">¿Sos mayor de 18 años?</h3>
            </div>
          </div>

          <div className="column">
            <div className="buttons">
              <button className="welcomeButton" onClick={onSubmit}>
                SI
              </button>
              <button className="welcomeButton" onClick={onCancel}>
                NO
              </button>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="welcomeTitle remember">
              <input type="checkbox" id="remember" />
              <label
                for="remember"
                onClick={() => {
                  setRemember(true)
                  window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Age Gate",
                    event_action: "Intercation",
                    event_label: "Recuerdame",
                    interaction: false,
                    component_name: "",
                    element_text: "",
                  })
                }}
              >
                RECORDARME
              </label>
            </div>
          </div>

          {/* terms and conditions */}
          <div className="row d-flex justify-content-center">
            <div className="col-md-6">
              <p className="welcomeParagraph">
                No se quede conectado si comparte el dispositivo con menores.
                Usamos cookies para mejorar nuestros servicios. Al continuar la
                navegación está aceptando los términos de uso, las políticas de
                privacidad y el uso de las cookies
              </p>
              <div className="row d-flex justify-content-center">
                <a
                  target="_blank"
                  download
                  href="/TyCCervezaCorona.pdf"
                  className="col-4 welcomeLinksTerms text-right"
                >
                  Términos de uso
                </a>
                <div className="col-1 welcomeLinksSeparator">|</div>
                <a
                  target="_blank"
                  download
                  href="/ppdp.pdf"
                  className="col-4 welcomeLinksTerms text-left"
                >
                  Políticas de privacidad
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Div100vh>
  )
}

export default AgeGateBoolean
