import "bootstrap/dist/css/bootstrap.css"
import "jquery/dist/jquery.min.js"
import "popper.js/dist/popper.min"
import "bootstrap/dist/js/bootstrap.min.js"
import "./src/styles/Global.css"
import StoreProvider from "./src/providers/StoreProvider"

// Hace que los estilos se mantengan al refrescar
import ReactDOM from 'react-dom'
export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}

export const wrapRootElement = StoreProvider

/*
import "./src/styles/layout.css";*/
