export const InformationToImages = data => {
  const images = data.allFile.edges //array to images
  const info = data.allMarkdownRemark.edges // array to info from images

  images.map(image => {
    //create new array 'images' adding the information of each image
    // console.log("image: ", image)
    const info_image = info.find(
      data => data.node.frontmatter.path === image.node.base
    )
    // console.log(info_image)
    image["information"] = info_image.node.frontmatter
  })

  images.sort((element1, element2) => {
    //order by slide
    if (element1.information.slide > element2.information.slide) {
      return 1
    } else if (element1.information.slide < element2.information.slide) {
      return -1
    }
    return 0
  })

  return images
}

export const InformationToImages_miCorona = data => {
  const images = data.allFile.edges //array to images
  const info = data.allMarkdownRemark.edges // array to info from images

  images.map(image => {
    //create new array 'images' adding the information of each image
    const info_image = info.find(
      data => data.node.frontmatter.path === image.node.base
    )
    if (info_image !== undefined) {
      //is a object
      image["information"] = info_image.node.frontmatter
    }
  })

  images.map(image => {
    //add background images to object
    if (image.information !== undefined) {
      //is a object
      image.information["background_photo"] = images.find(
        element => element.node.base === image.information.background
      )
      image.information["background_photo_mobile"] = images.find(
        element => element.node.base === image.information.background_mobile
      )
    }
  })

  const result = images.filter(image => image.hasOwnProperty("information")) //return only images whit information

  result.sort((element1, element2) => {
    //order by slide
    if (element1.information.slide > element2.information.slide) {
      return 1
    } else if (element1.information.slide < element2.information.slide) {
      return -1
    }
    return 0
  })

  return result
}

export const IsMobileDevice = () => {
  //return if device is mobile
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  )
}

export const CleanArrayImages = data => {
  //return a array of element clean of a consult grasphql (allFiles)

  const Images = []

  data.allFile.edges.forEach(element => {
    Images[element.node.name] = element.node.childImageSharp.fluid
  })

  return Images
}

export const checkIsTablet = (setIsTablet) => {
  const isTabletMediaQuery = window.matchMedia('(min-width: 768px) and (max-width: 1024px)');
  setIsTablet(isTabletMediaQuery.matches);
};

export const setupTabletListener = (setIsTablet) => {
  // Initial check
  checkIsTablet(setIsTablet);

  // Attach a listener for changes in screen size
  window.addEventListener('resize', () => checkIsTablet(setIsTablet));

  // Clean up the listener on component unmount
  return () => {
    window.removeEventListener('resize', () => checkIsTablet(setIsTablet));
  };
};

export const getAge = ()=>{
  if(typeof localStorage !== "undefined" || typeof sessionStorage !== "undefined"){
    const localAge = localStorage.getItem('age')
    const sessionAge = sessionStorage.getItem('age')
    return localAge || sessionAge
  }
  return false
}