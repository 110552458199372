import React,{useEffect, useState} from "react";
import { useStaticQuery } from "gatsby";
import Popup from "../components/PopUp/PopUp";
import { getAge } from "../utils";

import AgeGateBoolean from "../components/AgeGate/AgegateBoolean";
export default function({ children,location }){
    
    const [showPopUp,setShowPopUp] = useState(true)
    const [age, setAge] = useState(true);
    const url = typeof window !== "undefined" && window.location.pathname
    const local = getAge()
    useEffect(()=>{
        if (local) setAge(false)
    },[age])
               
    const data = useStaticQuery(graphql`
    query {
        allPopUp {
            edges {
              node {
                cta
                img
                link
                text_sup
                text_inf
                subdominio
                active
              }
            }
          }
    }
  `)
  const allpopUps = data.allPopUp.edges
    let popUpdata = allpopUps.find(popup =>`/${popup?.node?.subdominio}/` === url || popup?.node?.subdominio === url)
    console.log('debug_data:',{popUpdata,showPopUp,age})
    if(age)return <AgeGateBoolean location={location} setAge={setAge} />
    return <>
    {showPopUp && popUpdata && popUpdata?.node?.active && <Popup setShowPopUp={setShowPopUp} popupData={popUpdata.node}/>}
    { children }
    </>
}